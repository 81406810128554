import React from 'react';
import VkeyAppBar from './VkeyAppBar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {createTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import {lightTheme} from '../layout/themes'
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {translate} from 'react-admin';
import {PropTypes} from 'prop-types';
// import Background from './components/home_background.png'

const styles = (theme) => ({
    companyDesc: {
        height: 350,
        color: 'white',
        textAlign: 'center',
        padding: '60px 60px'
    },
    descText: {
        maxWidth: 700,
        margin: '30px auto',
        fontSize: '1.3em',
        fontWeight: 200,
    },
    productContent: {
        background: '#f2f4f4',
        minHeight: 300,
        padding: '60px 0',
        display: 'inline-flex',
        width: '100%',
        
    },
    orangeButton: {
        width: 300,
        color: 'rgb(255, 157, 0)',
        border: '2px solid rgb(223, 188, 0)',
        marginTop: 20
    },
    // Product css
    product: {
        maxWidth: '33.3%',
        flex: 1,
        margin: '0 30px',
    },
    productImage: {
        height: 200,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    productTitle: {
        fontWeight: 600,
        fontSize: '1.2rem',
        marginTop: 20,
        marginBottom: 10
    },
    productDescription: {
        fontWeight: 200,
        marginBottom: 20
    },
    productLauchLink: {
        textDecoration: 'none',
        fontSize: '1.2rem'
    },

    // Footer css
    footer: {
        width: '80%',
        margin: '0 auto'
    },
    socialFooter: {
        color: 'white',
        width: 26,
        height: 26,
        margin: '0 1.55rem'
    },
    hoverOpacity: theme.vkey.hoverOpacity,

    backgroundOverlay: {
        width: '100vw',
        height: '100vh',
        // opacity: .7,
        // backgroundColor: '#5c2d78',
        // backgroundImage: "linear-gradient(to top, #b17bd5, #5450ac 51%, #441a60)",
        backgroundSize: 'cover',
        position: 'fixed',
        zIndex: '2'
    }
})
class ExternalLayout extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            privacyHover: false,
            termsOfUseHover: false
        }

        this.privacyHover = this.privacyHover.bind(this)
        this.termsOfUseHover = this.termsOfUseHover.bind(this)
    }

    privacyHover() {
        this.setState({privacyHover: !this.state.privacyHover})
    }

    termsOfUseHover() {
        this.setState({termsOfUseHover: !this.state.termsOfUseHover})
    }

    UNSAFE_componentWillMount() {
        // document.body.style.background = 'url(assets/images/bg-bacground-body.png) center top no-repeat #e4e4e4'
        // document.body.style.background = 'linear-gradient(rgb(134, 117, 173) 10%, rgb(251, 194, 235) 100%) 0% 0% / cover fixed'
        document.body.style.backgroundSize = 'cover'
        document.body.style.backgroundAttachment = 'fixed'
        // document.body.style.backgroundImage = 'url("/assets/images/home_background.png")'
        document.body.style.backgroundPosition = 'center'
    }

    render() {
        let { classes, showFooter, forceShowHeader, history } = this.props
        return (
            <div id={'vkey-external-wrapper'} className="vkey-external-wrapper" style={{ ...this.props.style,
                // background: "url(assets/images/bg-bacground-body.png) center top no-repeat #e4e4e4",
                // background: 'linear-gradient(to bottom, rgb(161, 140, 209) 0%, rgb(251, 194, 235) 100%)',
                backgroundSize: 'cover',
                backgroundAttachment: 'fixed',
                overflowX: 'hidden',
                backgroundImage: 'url("/assets/images/sign_in_bak.png")',
                // height: '100vh'
            }}>
                <VkeyAppBar forceHover={forceShowHeader} history={history} />
                {/*<div className={classes.backgroundOverlay}></div>*/}
                <div style={{ minHeight: 'calc(100vh - 180px)' }}>{this.props.children}</div>

                { showFooter && <div>
                    <div style={{ backgroundColor: '#8d6ca1', display: 'block', textAlign: 'center', width: '100%', padding: '1rem 0', fontSize: '1.2rem', zIndex: 3, position: "relative" }}>
                    <a href="https://www.facebook.com/VKeyInc/" className={classes.socialFooter}><FontAwesomeIcon icon={['fab', 'facebook-f']} /></a>
                    <a href="https://twitter.com/vkey_inc" className={classes.socialFooter}><FontAwesomeIcon icon={['fab', 'twitter']} /></a>
                    <a href="https://www.linkedin.com/company/v-key-inc" className={classes.socialFooter}><FontAwesomeIcon icon={['fab', 'linkedin-in']} /></a>
                    </div>

                    <footer>
                        <div id="footer-wrapper" style={{ padding: '1.2rem', backgroundColor: 'white', textAlign: 'center', color: '#484647', zIndex: 3, position: "relative" }}>
                            <div style={{fontWeight: 600, lineHeight: 2}}>
                                <a href="/docs/intro/terms-of-use/terms-of-use" target="_blank"
                                   className={this.state.termsOfUseHover ? classes.hoverOpacity : null}
                                   style={{textDecoration: 'none', color: '#484647'}}
                                   onMouseEnter={this.termsOfUseHover}
                                   onMouseLeave={this.termsOfUseHover}>
                                    TERMS OF USE
                                </a>
                                  &nbsp;&nbsp;|&nbsp;&nbsp;
                                <a href="/docs/intro/privacy-policy/privacy-policy" target="_blank"
                                   className={this.state.privacyHover ? classes.hoverOpacity : null}
                                   style={{textDecoration: 'none', color: '#484647'}}
                                   onMouseEnter={this.privacyHover}
                                   onMouseLeave={this.privacyHover}>
                                    PRIVACY POLICY
                                </a>
                            </div>
                            <div>© 2025 V-Key Inc. All Rights Reserved</div>
                        </div>
                    </footer>
                </div>}
            </div>
        );
    }
}

ExternalLayout.propTypes = {
    showFooter: PropTypes.bool
};

const mapStateToProps = state => ({isLoading: state.admin.loading > 0});

const enhance = compose(
    translate,
    connect(
        mapStateToProps,
        {}
    ),
    withStyles(styles)
);

const EnhancedExternalLayout = enhance(ExternalLayout);

// We need to put the MuiThemeProvider decoration in another component
// Because otherwise the withStyles() HOC used in EnhancedLogin won't get
// the right theme
const HomeWithTheme = props => (
    <MuiThemeProvider theme={createTheme(lightTheme)}>
        <EnhancedExternalLayout {...props} />
    </MuiThemeProvider>
);

export default HomeWithTheme;
